body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.centered-element {
  position: absolute;
  top: 50%;
  width: 80%;
  text-align: center;
  line-height: 100px;
}

.grey-link:hover {
  color: #1976D2;
}


